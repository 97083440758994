import ArticleRecord from "./article.store";

const articleReducer = function (state, action) {
    if (state === undefined)
        state = new ArticleRecord();
    switch (action.type) {
        case 'SET_LAST_ARTICLE':
            state = state.merge({
                last: action.data
            });
            break;
        default:
            break;
    }
    return state;
}

export default articleReducer;