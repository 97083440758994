import React from 'react';
import { withRouter } from 'react-router-dom';
import { Route } from "react-router-dom";
import { connectStore } from '../../Store';
import UsersList from "./UsersList";
import UserEditView from "./UserEditView";

export class UserSection extends React.Component {
  render() {
    return (
        <>
            <Route path="/users/list" exact={true} component={UsersList} />
            <Route path="/users/edit/:userId" exact={true} component={UserEditView} />
        </>
    );
  }
}

export default withRouter(connectStore(UserSection), {});
