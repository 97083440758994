import React from 'react';
import {withRouter} from 'react-router';
import {connectStore} from '../../Store';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CreateIcon from '@material-ui/icons/Create';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const styles = () => ({});

export class ArticlesActionsMenu extends React.Component {
    state = {
        anchorEl: null,
    };

    handleClick = event => {
        this.setState({anchorEl: event.currentTarget});
    };

    handleClose = () => {
        this.setState({anchorEl: null});
    };

    render() {

        const {classes, user} = this.props;
        const {anchorEl} = this.state;

        return <>
            <IconButton
                aria-owns={anchorEl ? 'simple-menu' : undefined}
                aria-haspopup="true"
                onClick={this.handleClick}
            >
                <MoreVertIcon/>
            </IconButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={this.handleClose}
            >
                <MenuItem onClick={() => {
                    this.handleClose();
                    this.props.history.push('/users/edit/' + user._id);
                }}>
                    <ListItemIcon className={classes.icon}>
                        <CreateIcon/>
                    </ListItemIcon>
                    <ListItemText classes={{primary: classes.primary}} inset primary="Bearbeiten"/>
                </MenuItem>
            </Menu>
        </>;

    }
}

ArticlesActionsMenu.propTypes = {
    user: PropTypes.object.isRequired,
};

export default connectStore(withRouter(withStyles(styles)(ArticlesActionsMenu)), {});
