import React from 'react';
import { withRouter } from 'react-router-dom';

import withStyles from '@material-ui/core/styles/withStyles';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';


 

const styles = theme => ({
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
});

export class SubMenu extends React.Component {

  constructor(props) {
    super(props);
    
    this.classes = props.classes;
  }

  render() {        
    return (
        <List>
            {this.props.menu.map((item) => (
                <ListItem button key={item.link} onClick={(e) => { this.props.history.push(item.link); }} selected={(this.props.history.location.pathname.includes(item.link))} >
                    {item.icon && <ListItemIcon>
                        {item.icon}
                    </ListItemIcon>}
                    <ListItemText primary={item.label} secondary={item.description} />
                </ListItem>
            ))}
        </List>
    );
  }
};

export default withRouter(withStyles(styles)(SubMenu));