import React from 'react';
import {withRouter} from 'react-router';
import request from 'superagent';

import {connectStore} from '../Store';
import {Map, TileLayer, FeatureGroup} from 'react-leaflet';
import {EditControl} from "react-leaflet-draw";
import L from 'leaflet';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {Grid} from '@material-ui/core';


export class PolygonEdit extends React.Component {
    constructor(props) {
        super(props);

        var state = {
            viewport: {
                center: [53.1610859235444, 8.72314453125],
                zoom: 6,
            },
            polygon: this.props.polygon,
            addPolygon: false,
        };

        if (this.props.new) {
            state.addPolygon = true;
            // Create empty Polygon
            state.polygon = {
                title: "",
                places: [],
                nominatims: [],
                geoJson: "",
            }
        }

        this._onEditPath = this._onEditPath.bind(this);
        this._onCreatedPath = this._onCreatedPath.bind(this);
        this._onFinishPath = this._onFinishPath.bind(this);
        this.changeTextField = this.changeTextField.bind(this);
        this.save = this.save.bind(this);

        this.state = state;
    }

    _onCreatedPath() {
        this._onFinishPath();
        this.setState({
            addPolygon: false,
        });
    }

    _onEditPath() {
        this._onFinishPath();
    }

    _onFinishPath() {
        if (!this._editableFG) {
            return;
        }
        const geojsonData = this._editableFG.leafletElement.toGeoJSON().features[0].geometry;
        const polygon = Object.assign({}, this.state.polygon);
        polygon.geoJson = geojsonData;

        this.setState({
            polygon: polygon,
        });
    }


    componentWillUpdate() {
        this._onFeatureGroupReady();
    }

    _onFeatureGroupReady = (reactFGref) => {
        // populate the leaflet FeatureGroup with the geoJson layers
        if (!reactFGref) {
            reactFGref = this._editableFG;
        }
        let leafletFG = reactFGref.leafletElement;
        leafletFG.clearLayers();

        let leafletGeoJSON = new L.GeoJSON(this.state.polygon.geoJson);
        leafletGeoJSON.eachLayer((layer) => {
            leafletFG.addLayer(layer);
        });

        if (this.refs.map && !this.props.new) {
            var map = this.refs.map.leafletElement;
            map.fitBounds(leafletFG.getBounds());
        }

        // store the ref for future access to content
        this._editableFG = reactFGref;
    }


    /**
     * Change the value of the polygon entity
     * @param {*} key
     * @param {*} value
     */
    changeTextField(key, value) {
        let polygon = Object.assign({}, this.state.polygon);
        polygon[key] = value;

        this.setState({
            polygon: polygon,
        });
    }


    /**
     * Saves the state.polygon to the server!
     * @param {*} e
     */
    save(e) {
        let polygon = this.state.polygon;

        let data = Object.assign({}, polygon);
        data._id = undefined;
        data.gid = undefined;
        data.createdAt = undefined;
        data.updatedAt = undefined;
        data.center = undefined;
        data.geoProblems = undefined;
        data.nominatims = undefined;
        data.places = undefined;
        data.__v = undefined;

        let newRequest = request.put(this.props.app.host + '/polygon/' + polygon._id);
        if (this.props.new) {
            newRequest = request.post(this.props.app.host + '/polygon');
        }

        newRequest.set('Authorization', 'Bearer ' + this.props.auth.token)
            .send(data)
            .then(res => {
                this.props.dispatch({type: 'NEW_SNACKBAR_MASSAGE', message: 'Polygon Abgespeichert!'});
                this.props.history.goBack();
            })
            .catch((err) => {
                this.props.dispatch({type: 'NEW_SNACKBAR_MASSAGE', message: 'Das hat leider nicht geklappt!'});
                console.error("REQUEST FAILED", err);
            });


    }


    render() {
        const styleWrapper = {
            display: 'flex',
            flexDirection: 'column',
        }

        const styleContent = {
            flex: '1 0 0',
        }

        let polygonEdit = null;
        if (this.state.polygon) {
            polygonEdit = (
                <Grid container spacing={24} style={{height: "100%",}}>
                    <Grid item xs={4} style={{padding: 24}}>
                        <TextField
                            label="Titel"
                            fullWidth={true}
                            onChange={(e) => {
                                this.changeTextField('title', e.target.value)
                            }}
                            defaultValue={this.state.polygon.title}
                        />

                        <TextField
                            label="Orte"
                            fullWidth={true}
                            disabled={true}
                            defaultValue={this.state.polygon.places.map((place) => {return place.title;}).join(', ') + ' '}
                        />

                        <TextField
                            label="Verwaltungsebenen"
                            fullWidth={true}
                            disabled={true}
                            defaultValue={this.state.polygon.nominatims.map((nominatim) => {return nominatim.title;}).join(', ') + ' '}
                        />

                        <TextField
                            label="Erstellt am"
                            fullWidth={true}
                            disabled={true}
                            defaultValue={this.state.polygon.createdAt}
                        />

                        <TextField
                            label="Zuletzte geändert"
                            fullWidth={true}
                            disabled={true}
                            defaultValue={this.state.polygon.updatedAt}
                        />
                        <Button color="primary" variant="contained" fullWidth={true}
                                onClick={this.save}>Speichern</Button>
                        <Button fullWidth={true} onClick={(e) => {
                            this.props.history.goBack();
                        }}>Abbrechen</Button>
                    </Grid>

                    <Grid item xs={8} style={styleWrapper}>
                        <Map
                            style={styleContent}
                            viewport={this.state.viewport}
                            ref="map"
                        >
                            <TileLayer
                                attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <FeatureGroup ref={(reactFGref) => {
                                this._onFeatureGroupReady(reactFGref);
                            }}>
                                <EditControl
                                    position='topright'
                                    onEdited={this._onEditPath}
                                    onCreated={this._onCreatedPath}
                                    onDeleted={this._onDeleted}
                                    draw={{
                                        rectangle: false,
                                        polygon: this.state.addPolygon,
                                        circle: false,
                                        polyline: false,
                                        marker: false,
                                        circlemarker: false,
                                    }}
                                    edit={{
                                        remove: false,
                                    }}
                                />

                            </FeatureGroup>
                        </Map>
                    </Grid>
                </Grid>
            );
        }

        return (
            <div style={{height: '100%',}}>
                {polygonEdit}
            </div>
        );
    }
};

export default connectStore(withRouter(PolygonEdit), {});
