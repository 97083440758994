import React from 'react';
import { withRouter } from 'react-router';
import { connectStore } from '../../../Store';
import { withStyles } from '@material-ui/core/styles';
import axios from "axios";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import MapPolygonsView from '../../../ui/MapPolygonsView';



const styles = theme => ({
    card: {
        overflow: "visible",
    },
    media: {
        objectFit: 'contain',
      },
    mapView: {
        height: 300,
        width: '100%',
    }
  });


export class PlacesView extends React.Component {
    
    id = "null";

    constructor(props) {
        super(props);

        this.loadData = this.loadData.bind(this);
        this.reload = this.reload.bind(this);

        this.state = {
            place: {},
            id: ""
        };        
    }

    componentDidMount() {
        this.reload();
    }

    componentDidUpdate() {
       this.reload();
    }

    reload() {
        if (this.id !== this.props.id) {
            this.loadData();
            this.id = this.props.id;
        }
    }

    async loadData() {

        let path = this.props.app.host + '/place/' + this.props.id;

        let response = await axios({
            method: 'get',
            url: path,
        });
        
        if (response) {
            this.setState({
                place: response.data,
            });
        }

    }

    render() {
        if (!this.state.place) {
            return;
        }
        const place = this.state.place;

        const { classes } = this.props;

        return <Card className={classes.card}>
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
                {place.title}
            </Typography>

            <Typography component="div" className={classes.mapView}>
                <MapPolygonsView polygons={place.polygons} />
            </Typography>

            
            
            <Typography component="p"><b>Postleitzahl: </b> {place.zip}</Typography>
            <Typography component="p"><b>Landkreis: </b> {place.substate}</Typography>
            <Typography component="p"><b>Bundesland: </b> {place.state}</Typography>
            <Typography component="p"><b>Verwaltungsbereich: </b> {place.district}</Typography>
            <Typography component="p"><b>Land: </b> {place.country}</Typography>
            <Typography component="p"><b>GID: </b> {place.gid}</Typography>
            
          </CardContent>
      </Card>;
    }
};

export default connectStore(withRouter(withStyles(styles)(PlacesView)), {
    article: true,
    tableSettings: true,
});