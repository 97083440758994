import { Record } from 'immutable';

const ip = (process.env.REACT_APP_BACKEND_HOST) ? process.env.REACT_APP_BACKEND_HOST : "http://localhost";
const endpoint = (process.env.REACT_APP_BACKEND_ENDPOINT) ? process.env.REACT_APP_BACKEND_ENDPOINT : "";
const port = (process.env.REACT_APP_BACKEND_PORT) ? process.env.REACT_APP_BACKEND_PORT : "5000";
const host =  ip+":"+port + endpoint;

// The App Reducer
const AppStateRecord = Record({
    busy: false,
    host: host
});

export default AppStateRecord;
