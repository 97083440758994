import React from 'react';
import {withRouter} from 'react-router';
import {connectStore} from '../../../Store';
import {withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import axios from "axios";
import Typography from '@material-ui/core/Typography';
import {Button, LinearProgress, CircularProgress} from '@material-ui/core';


const styles = theme => ({
    paper: {
        padding: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
    },
    button: {
        marginRight: theme.spacing.unit,
    }
});

export class ExportView extends React.Component {
    stopLoop = false;

    constructor(props) {
        super(props);

        this.state = {
            startedExport: "",
            status: {},
        };

        this.start = this.start.bind(this);
        this.status = this.status.bind(this);
        this.download = this.download.bind(this);

        this.status();
    }

    async start(type = "regio") {
        let path = this.props.app.host + '/export/start/' + type;
        await axios({
            method: 'get',
            url: path,
            headers: {
                Authorization: "Bearer " + this.props.auth.apiToken,
            }
        });

        this.setState({
            startedExport: type,
            status: {
                run: true,
                percent: 0,
                duration: 0,
            }
        });

        this.status();
    }

    async status() {
        let path = this.props.app.host + '/export/status';
        let response = await axios({
            method: 'get',
            url: path,
            headers: {
                Authorization: "Bearer " + this.props.auth.apiToken,
            }
        });

        this.setState({status: response.data});

        setTimeout(() => {
            if (response.data.run && !this.stopLoop) {
                this.status();
            } else {
                this.setState({
                    status: {run: false},
                });
            }
        }, 5000);
    }

    async download(type) {
        let path = this.props.app.host + '/export/files/';

        switch (type) {
            case "tak":
                path += "regiotool-tak.csv";
                break;
            default:
                path += "regiotool-all.csv";
        }

        window.open(path);
    }

    componentWillUnmount() {
        this.stopLoop = true;
    }

    render() {
        const {classes} = this.props;

        if (!this.state.status || !this.state.status.hasOwnProperty("run")) {
            return <CircularProgress/>
        }

        return <>
            <Typography variant="h3" gutterBottom>Exportieren</Typography>
            <Paper className={classes.paper}>
                <Typography variant="h4" gutterBottom>Regiotool</Typography>
                {this.state.status && !this.state.status.run && <div>
                    <Button
                        variant="contained"
                        className={classes.button}
                        color="primary"
                        onClick={() => {
                            this.start('regio')
                        }}
                    >Starten</Button>
                    <Button
                        variant="contained"
                        className={classes.button}
                        onClick={() => {
                            this.download('regio')
                        }}
                    >Letzte CSV-Datei downloaden</Button>
                </div>}

                {this.state.status && this.state.status.run && this.state.status.type !== "tak" && <div>
                    <LinearProgress color="primary" variant="determinate" value={this.state.status.percent}/>
                    <Typography gutterBottom>{Math.round(this.state.status.percent)}% -
                        Dauer: {this.state.status.duration}min</Typography>
                    <Typography gutterBottom>Es kann nur ein Export ausgeführt werden. Andere Exporte können nicht
                        gestartet werden.</Typography>
                </div>}
            </Paper>

            <Paper className={classes.paper}>
                <Typography variant="h4" gutterBottom>TAK</Typography>
                {this.state.status && !this.state.status.run && <div>
                    <Button variant="contained" className={classes.button} color="primary" onClick={() => {
                        this.start('tak')
                    }}>Starten</Button>
                    <Button variant="contained" className={classes.button} onClick={() => {
                        this.download('tak')
                    }}>Letzte CSV-Datei downloaden</Button>
                </div>}

                {this.state.status && this.state.status.run && this.state.status.type === "tak" && <div>
                    <LinearProgress color="primary" variant="determinate" value={this.state.status.percent}/>
                    <Typography gutterBottom>{Math.round(this.state.status.percent)}% -
                        Dauer: {this.state.status.duration}min</Typography>
                    <Typography gutterBottom>Es kann nur ein Export ausgeführt werden. Andere exporte können nicht
                        gestartet werden.</Typography>
                </div>}
            </Paper>
        </>
    }
}

export default connectStore(withRouter(withStyles(styles)(ExportView)), {
    article: true,
    tableSettings: true,
});
