import React from 'react';
import {withRouter, Redirect} from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import BookIcon from '@material-ui/icons/Book';
import PlaceIcon from '@material-ui/icons/Place';
import AccountBox from '@material-ui/icons/AccountBox';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Avatar from '@material-ui/core/Avatar';
import {Route, Switch} from "react-router-dom";
import Login from './views/Login';
import MainMenu from './ui/layout/MainMenu';
import ArticlesSection from './views/articles/ArtcilesSection';
import RegioSection from './views/regio/RegioSection';
import {connectStore} from './Store';
import axios from 'axios';
import UserSection from "./views/user/UserSection";
import UserInfo from "./views/user/UserInfo";
import ArticleViewMap from "./views/articles/ArticleViewMap";
import ArticleView from "./views/articles/ArticleView";

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
        textAlign: "left",
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        marginLeft: theme.spacing.unit * 7 + 1,
        [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
            marginLeft: theme.spacing.unit * 7 + 1,
            marginTop: 48,
        },
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing.unit * 9 + 1,
            marginTop: 64,
        },
    },
    contentLogout: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        marginLeft: theme.spacing.unit * 1 + 1,
        [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
            marginLeft: theme.spacing.unit + 1,
            marginTop: 0,
        },
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing.unit + 1,
            marginTop: 0,
        },
    },
    toolbar: {
        minHeight: 48,
    },
    avatar: {
        marginRight: theme.spacing.unit,
        background: "#ffffff",
        width: 40,
        height: 40,
    },
});

export class App extends React.Component {

    constructor(props) {
        super(props);
        this.props = props;

        axios.interceptors.response.use((response) => {
            return response;
        }, (error) => {
            // Do something with response error
            if (error.response.status === 401) {
                console.log('unauthorized, logging out ...');
                this.props.dispatch({
                    type: 'AUTH_LOGGED_OUT'
                });
            }
            return Promise.reject(error.response);
        });
        this.state = {prevPath: this.props.location};
        this.classes = props.classes;
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.location !== this.props.location) {
            this.setState({prevPath: this.props.location})
        }
    }

    render() {
        const menu = [
            {
                tooltip: "Produkte",
                icon: <BookIcon/>,
                link: "/articles/list",
            },
            {
                tooltip: "Regiotool",
                icon: <PlaceIcon/>,
                link: "/regio",
            },
        ]
        if (this.props.auth.scopes.indexOf('admin') !== -1) {
            menu.push({
                tooltip: "User administration",
                icon: <AccountBox/>,
                link: "/users/list",
            });
        }
        menu.push({
            tooltip: "User self service",
            icon: <AccountCircle/>,
            link: "/userdetail",
        });

        if (!this.props.auth.token) {
            return (
                <React.Fragment>
                    <div>
                        <main className={this.classes.contentLogout}>
                            <Switch>
                                <Route path="/login/" exact={true} render={props =>
                                    (<Login {...props} prevPath={this.state.prevPath}/>)
                                }/>
                                <Route path="/articles/view/:itemId" exact={true} component={ArticleView}/>
                                <Route path="/articles/viewmap/:itemId" exact={true} component={ArticleViewMap}/>
                                <Route exact path="*" render={() => <Redirect to="/login"/>}/>
                            </Switch>
                        </main>
                    </div>
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <div>
                    <AppBar position="fixed">
                        <Toolbar>
                            <Avatar className={this.classes.avatar} src="/img/logo-square.svg"/>
                            <Typography variant="h6" color="inherit" className={this.classes.grow}>
                                VH Tools
                            </Typography>
                            <Button onClick={() => {
                                this.props.dispatch({
                                    type: 'AUTH_LOGGED_OUT'
                                });
                            }}>Abmelden</Button>
                        </Toolbar>
                    </AppBar>
                    <div>
                        <MainMenu menu={menu}/>
                        <main className={this.classes.content}>
                            <Switch>
                                <Route exact path="/login" render={() => <Redirect to="/"/>}/>
                                <Route path="/articles/view/:itemId" exact={true} component={ArticleView}/>
                                <Route path="/articles/viewmap/:itemId" exact={true} component={ArticleViewMap}/>
                                <Route path="/articles/" exact={false} component={ArticlesSection}/>
                                <Route path="/regio" exact={false} component={RegioSection}/>
                                <Route path="/users" exact={false} component={UserSection}/>
                                <Route path="/userdetail" exact={true} params={this.props.params} component={UserInfo}/>
                            </Switch>
                        </main>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(connectStore(withStyles(styles)(App)), {});
