import React from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import { Drawer } from '@material-ui/core';

const sidebarWidth = 250;
const styles = theme => ({
    drawer: {
        width: sidebarWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        marginTop: 56, 
        overflowX: 'hidden',
        marginLeft:  theme.spacing.unit * 7 + 2,
        width: sidebarWidth,
        [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
            marginLeft: theme.spacing.unit * 7 + 2,
            marginTop: 48,
        },
        [theme.breakpoints.up('sm')]: { 
            marginLeft: theme.spacing.unit * 9 + 2,
            marginTop: 64, 
       }, 
    },
    content: {
        position: "relative",
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        marginLeft: sidebarWidth + 2,
        padding: theme.spacing.unit * 3,
        [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
            marginLeft: sidebarWidth + 2,
            marginTop: 48,
        },
        [theme.breakpoints.up('sm')]: { 
            marginLeft: sidebarWidth + 2,
            marginTop: 64, 
       }, 
    },
});

export class SecondMenuLayout extends React.Component {

  constructor(props) {
    super(props);
    this.classes = props.classes;
  }

  render() {             
    return (
        <div>
            <Drawer
                className={this.classes.drawer}
                variant="permanent"
                classes={{
                paper: this.classes.drawerPaper,
                }}
                anchor="left"
            >
                {this.props.menu}
            </Drawer>
            <main className={this.classes.content}>
                {this.props.children}
            </main>
        </div>
    );
  }
};

export default withStyles(styles)(SecondMenuLayout);