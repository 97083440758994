import React from 'react';
import {withRouter} from 'react-router';
import {withStyles} from '@material-ui/core/styles';
import {connectStore} from '../Store';
import Loading from '../ui/Loading';
import MapPolygonsView from '../ui/MapPolygonsView';
import Grid from '@material-ui/core/Grid';
import {EntityView} from "./EntityView";

const styles = theme => ({
    headline: {
        marginLeft: theme.spacing.unit,
    },
});

export class EntityViewMap extends EntityView {
    render() {
        let articleMapView = <></>;
        if (this.state.item && this.state.item.polygons.length > 0) {
            articleMapView = <MapPolygonsView fullview={!!this.props.fullview} polygons={this.state.item.polygons}/>;
        }
        return (
            <Loading>
                <Grid container>
                    <Grid item xs={12}>
                        {articleMapView}
                    </Grid>
                </Grid>
            </Loading>
        );
    }
}

export default connectStore(withRouter(withStyles(styles)(EntityViewMap)), {
    article: true,
});
