import React from 'react';
import { withRouter } from 'react-router-dom';
import { Route } from "react-router-dom";
import { connectStore } from '../../Store';
import ArticlesList from "./ArticlesList";
import ArticleEditView from "./ArticleEditView";

export class ArticleSection extends React.Component {
  render() {
    return (
        <>
            <Route path="/articles/list" exact={false} component={ArticlesList} />
            <Route path="/articles/edit/:itemId" exact={false} component={ArticleEditView} />
        </>
    );
  }
}

export default withRouter(connectStore(ArticleSection), {});
