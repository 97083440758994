import React from 'react';
import { withRouter } from 'react-router';
import { connectStore } from '../../../Store';
import EntityEdit from '../../../ui/EntityEdit';
import { Paper, Typography } from '@material-ui/core';

export class PlaceEditView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            new: false,
        }
    }
    render() {
            var formConfiguration = [
                {
                    id: 'title',
                    type: 'text',
                    validate: (value) => { return (value === "") ? false : true; },
                    label: 'Name des Ortes',
                },
                {
                    id: 'zip',
                    type: 'text',
                    validate: (value) => { var pattern = /^\d+$/; return pattern.test(value); },
                    label: 'Postleitzahl',
                    errorText: 'Es sind nur Nummern erlaubt!',
                },
                {
                    id: 'country',
                    type: 'text',
                    validate: (value) => { var pattern = /^(DE|AT)$/; return pattern.test(value); },
                    label: 'Land',
                    errorText: 'Es ist nur DE oder AT erlaubt!',
                },
                {
                    id: 'state',
                    type: 'text',
                    label: 'Bundesland',
                },
                {
                    id: 'substate',
                    type: 'text',
                    label: 'Regierungsbezirk',
                },
                {
                    id: 'district',
                    type: 'text',
                    label: 'Landkreis',
                },
                {
                    id: 'keywords',
                    type: 'text',
                    label: 'Sonstige Suchworte',
                    errorText: '',
                },
                {
                    id: 'createdAt',
                    type: 'disabled',
                    label: 'Erstellt am',
                },
                {
                    id: 'updatedAt',
                    type: 'disabled',
                    label: 'Zuletzt geändert am',
                },
                {
                    id: 'submit',
                    type: 'submit',
                    label: 'Speichern',
                },
                {
                    id: 'cancle',
                    type: 'cancle',
                    label: 'Abbrechen',
                }
            ];

        return (
            <>
                <Typography variant="h3" gutterBottom>
                        Ort {(this.props.match.params.placeId === 'new') ? " erstellen" : " bearbeiten"}
                </Typography>
                <Paper>
                    <EntityEdit editFields={formConfiguration} endpoint="place" params={this.props.params} />
                </Paper>
            </>
        );
    }
};

export default connectStore(withRouter(PlaceEditView), {
    article: true,
});
