import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';

const drawerWidth = 400;
const styles = theme => ({
    drawerWrapper: {
      width: '100%',
      display: 'flex',
    },
    drawerRight: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        marginTop: 56,
        height: 'calc(100% - 56px)',
        overflow: "auto",
        [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
            marginTop: 48,
            height: 'calc(100% - 48px)',
        },
        [theme.breakpoints.up('sm')]: { 
            marginTop: 64, 
            height: 'calc(100% - 64px)',
       }, 
    },
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing.unit * 3,
        overflow: "hidden",
        position: "relative",
    },
  });



export class SidebarRight extends React.Component {

    render() {

        const { classes } = this.props;
        return <div className={classes.drawerWrapper}>
            <main className={classes.content}>
                {this.props.children}
            </main>
            {this.props.sidebar && <Drawer
                className={classes.drawerRight}
                variant="permanent"
                classes={{
                    paper: classes.drawerPaper,
                }}
                anchor="right"
            >
                {this.props.sidebar}
            </Drawer>}
        </div>;
    }
};

export default withStyles(styles)(SidebarRight);