import { Record } from 'immutable';

// The Authentication Reducer
const AuthStateRecord = Record({
    loggedIn: false,
    token: '',
    apiToken: '',
    scopes: [],
});

export default AuthStateRecord;
