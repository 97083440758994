import AppStateRecord from './app.store';

const appReducer = function (state, action) {
    if (state === undefined)
        state = new AppStateRecord();
    switch (action.type) {
        case 'APP_BUSY':
            state = state.merge({
                busy: true
            });
            break;
        case 'APP_IDLE':
            state = state.merge({
                busy: false
            });
            break;
        default:
            break;
    }
    return state;
}

export default appReducer;