import { Map } from 'immutable';
import TableSettingRecord from "./tableSettings.store";

const tableSettingsReducer = function (state, action) {
    if (state === undefined) {
        state = new Map();
    }
        
    switch (action.type) {
        case 'TABLESETTING_SET':
            if (action.data){
                if (state.has(action.data.id)) {
                    let existing = state.get(action.data.id);

                    if (action.data.fetch) {
                        existing = existing.set("fetch", {...{}, ...existing.get("fetch"), ...action.data.fetch});
                        delete action.data.fetch;
                    }

                    if (action.data.rows) {
                        existing = existing.set("rows", action.data.rows);
                        delete action.data.rows;
                    }

                    existing = existing.merge(action.data);
                    state = state.set(action.data.id, existing);
                } else {
                    state = state.set(action.data.id, new TableSettingRecord(action.data));
                }

            }

            break;
        default:
            break;
    }
    return state;
}

export default tableSettingsReducer;
