import React from 'react';
import { withRouter } from 'react-router';
import { connectStore } from '../../Store';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import axios from "axios";
import SidebarRight from "../../ui/layout/SidebarRight";
import Typography from '@material-ui/core/Typography';
import EnhancedTable from '../../ui/table/EnhancedTable';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import UsersActionsMenu from "./UsersActionsMenu";

const styles = theme => ({
    table: {
        marginTop: theme.spacing.unit * 3,
    },
    fab: {
        position: "absolute",
        top: theme.spacing.unit * 2,
        right: theme.spacing.unit * 2,
    }
});


export class UsersList extends React.Component {
    tableSettingKey = 'allUsers';

    constructor(props) {
        super(props);

        this.loadUsers = this.loadUsers.bind(this);
    }

    async loadUsers() {
        const tableSetting = this.props.tableSettings[this.tableSettingKey];
        const path = this.props.app.host + '/users?limit=' + tableSetting.fetch.rowsPerPage + '&skip=' + (tableSetting.fetch.page) * tableSetting.fetch.rowsPerPage + "&sortorder=" + tableSetting.fetch.order + "&sortfield=" + tableSetting.fetch.orderBy;

        let response = await axios({
            method: 'get',
            url: path,
        });
        
        if (response) {
            this.props.dispatch({
                type: 'TABLESETTING_SET',
                data: {
                    id: this.tableSettingKey,
                    rows: response.data.map((user) => {
                        user.scopeString = user.scope.join(', ');
                        return user;
                    }),
                    filteredRows: response.data,
                    rowsLength: response.data.length
                },
            });
        }
    }

    render() {
        const { classes } = this.props;

        let sidebarContent = null;

        return(
            <SidebarRight sidebar={sidebarContent}>
            <Typography variant="h3" gutterBottom>
                Benutzer
            </Typography>

            <Fab color="primary"
                 variant="extended"
                 aria-label="Add"
                 className={classes.fab}
                 onClick={() => {
                     this.props.history.push("/users/edit/new");
                 }}
            >
                <AddIcon/>
                Neuer Benutzer
            </Fab>

            {this.props.tableSettings[this.tableSettingKey] && <Card>
            </Card>}

            <Paper className={classes.table}>
                <EnhancedTable
                    tableSettingsKey={this.tableSettingKey}
                    tableColumns={[
                        { id: 'email', numeric: false, disablePadding: false, label: 'E-Mail / Login' },
                        { id: 'name', numeric: false, disablePadding: true, label: 'Name' },
                        { id: 'scopeString', numeric: false, allowSortBy: false, disablePadding: true, label: 'Gruppen' },
                    ]}
                    actions={item => <UsersActionsMenu user={item} />}
                    dataFetch={this.loadUsers}
                />
            </Paper>

        </SidebarRight>);
    }
}

export default connectStore(withRouter(withStyles(styles)(UsersList)), {
    tableSettings: true,
});
