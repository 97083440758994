import React from 'react';
import { withRouter } from 'react-router';
import Auth from '../Auth';
import { connectStore } from '../Store';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import red from '@material-ui/core/colors/red';

const styles = theme => ({
    background: {
        background: theme.palette.primary.main,
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
    },
    main: {
      width: 'auto',
      display: 'block', // Fix IE 11 issue.
      marginLeft: theme.spacing.unit * 3,
      marginRight: theme.spacing.unit * 3,
      [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
        width: 400,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    paper: {
      marginTop: theme.spacing.unit * 8,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
      margin: theme.spacing.unit,
      backgroundColor: theme.palette.secondary.main,
      width: 100,
      height: 100,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing.unit,
    },
    submit: {
      marginTop: theme.spacing.unit * 3,
    },
    errorMessage: {
        color: red[700],
    },
  });

export class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            message: '',
            remember: false,
            isLoading: false,
            prevPath: '',
        };

        this.classes = props.classes;

        this.Auth = new Auth(this.props);

        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.handleChangeUsername = this.handleChangeUsername.bind(this);
        this.handleChangeRemember = this.handleChangeRemember.bind(this);
        this.login = this.login.bind(this);
    }

    handleChangePassword(event) {
        this.setState({password: event.target.value});
    }

    handleChangeUsername(event) {
        this.setState({username: event.target.value});
    }

    handleChangeRemember(event) {
        this.setState({remember: event.target.checked});
    }

    login(event) {
        event.preventDefault();
        this.setState({ message: undefined });
        this.Auth.login(this.state.username, this.state.password, this.state.remember).then((res) => {
            if(res) {
                console.log( this.props);
                if (this.props.prevPath && this.props.prevPath !== '/login') {
                    this.props.history.push(this.props.prevPath);
                } else {
                    this.props.history.push('/');
                }
            } else {
                this.setState({ message: "Bitte Überprüfe, ob deine Zugangsdaten korrekt sind!" });
            }
        });
    }

    render() {
        return (
            <div className={this.classes.background}>
            <main className={this.classes.main}>
            <CssBaseline />
            <Paper className={this.classes.paper}>

                <Avatar className={this.classes.avatar} src="/img/apple-touch-icon.png"/>

                <Typography component="h1" variant="h5">
                    Verlagshaus Tools
                </Typography>

                {this.state.message && <Typography component="p" className={this.classes.errorMessage}>
                    {this.state.message}
                </Typography>}

                <form className={this.classes.form} onSubmit={this.login}>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="email">Email</InputLabel>
                            <Input id="email" name="email" autoComplete="email" autoFocus onChange={this.handleChangeUsername} />
                        </FormControl>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="password">Passwort</InputLabel>
                            <Input name="password" type="password" id="password" autoComplete="current-password" onChange={this.handleChangePassword} />
                        </FormControl>
                        <FormControlLabel
                            control={<Checkbox value="remember" color="primary" onChange={this.handleChangeRemember} />}
                            label="Angemeldet bleiben"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={this.classes.submit}
                        >
                            Anmelden
                        </Button>
                </form>
            </Paper>
            </main>
            </div>
        );
    }
}

export default connectStore(withRouter(withStyles(styles)(Login)), {
});
