import React from 'react';
import { withRouter } from 'react-router-dom';

import { Drawer } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import List from '@material-ui/core/List';
import Tooltip from '@material-ui/core/Tooltip';

const styles = theme => ({
    drawer: {
        width: theme.spacing.unit * 7 + 1,
        flexShrink: 0,
    },
    drawerPaper: {
        marginTop: 56,
        overflowX: 'hidden',
        width: theme.spacing.unit * 7 + 1,
        [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
            width: theme.spacing.unit * 7 + 1,
            marginTop: 48,
        },
        [theme.breakpoints.up('sm')]: { 
            width: theme.spacing.unit * 9 + 1,
            marginTop: 64, 
       }, 
    },
});

export class MainMenu extends React.Component {

  constructor(props) {
    super(props);
    this.classes = props.classes;
  }

  render() {             
    return (
        <Drawer
            className={this.classes.drawer}
            variant="permanent"
            classes={{
              paper: this.classes.drawerPaper,
            }}
            anchor="left"
          >
            <List>
                {this.props.menu.map((item) => (
                    <Tooltip title={item.tooltip} placement="right" key={item.link}>
                        <ListItem button  onClick={(e) => { this.props.history.push(item.link); }} selected={(this.props.history.location.pathname.includes(item.link))} >
                            <ListItemIcon>
                                {item.icon}
                            </ListItemIcon>
                        </ListItem>
                    </Tooltip>
                ))}
                
            </List>
        </Drawer>
                     
    );
  }
};

export default withRouter(withStyles(styles)(MainMenu));