import React from 'react';
import { withRouter } from 'react-router';
import { connectStore } from '../../Store';
import { withStyles } from '@material-ui/core/styles';
import axios from "axios";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import MapPolygonsView from '../../ui/MapPolygonsView';



const styles = theme => ({
    card: {
        overflow: "visible",
    },
    media: {
        objectFit: 'contain',
      },
    mapView: {
        height: 300,
        width: '100%',
        marginTop: theme.spacing.unit * 1,
    }
  });


export class ArticlesView extends React.Component {
    
    id = "null";

    constructor(props) {
        super(props);

        this.loadArticle = this.loadArticle.bind(this);
        this.reload = this.reload.bind(this);

        this.state = {
            article: {},
            id: ""
        };        
    }

    componentDidMount() {
        this.reload();
    }

    componentDidUpdate() {
       this.reload();
    }

    reload() {
        if (this.id !== this.props.id) {
            this.loadArticle();
            this.id = this.props.id;
        }
    }

    async loadArticle() {

        let path = this.props.app.host + '/article/' + this.props.id;

        let response = await axios({
            method: 'get',
            url: path,
        });
        
        if (response) {
            this.setState({
                article: response.data,
            });
        }

    }

    render() {
        if (!this.state.article) {
            return;
        }
        const article = this.state.article;

        const { classes } = this.props;

        return <Card className={classes.card}>
          <CardMedia
            component="img"
            alt={article.title}
            className={classes.media}
            height="200"
            image={"https://verlagshaus24.de/__import/" + article.sku + ".jpg"}
            title="Contemplative Reptile"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
                {article.title}
            </Typography>
            <Typography component="p"><b>ISBN: </b> {article.isbn}</Typography>
            <Typography component="p"><b>Vips Artikelnummer: </b> {article.sku}</Typography>
            <Typography component="p"><b>Im Export berücksichtigt: </b> {article.active ? 'ja' : 'nein'}</Typography>
            <Typography component="p"><b>Erstellt am: </b> {article.createdAt}</Typography>
            <Typography component="p"><b>Zuletzt bearbeitet am: </b> {article.updatedAt}</Typography>
            <Typography component="div" className={classes.mapView}>
                <b>Geo Markierung:</b><br />
                <MapPolygonsView polygons={article.polygons} />
            </Typography>
            
          </CardContent>
      </Card>;
    }
};

export default connectStore(withRouter(withStyles(styles)(ArticlesView)), {
    article: true,
    tableSettings: true,
});
