import React from 'react';
import {withRouter} from 'react-router';
import {withStyles} from '@material-ui/core/styles';
import request from 'superagent';
import {connectStore} from '../Store';
import Loading from '../ui/Loading';
import Form from '../ui/form/Form';
import MapPolygonsView from '../ui/MapPolygonsView';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Layers from '@material-ui/icons/Layers';
import Grid from '@material-ui/core/Grid';
import {Typography} from '@material-ui/core';

const styles = theme => ({
    headline: {
        marginLeft: theme.spacing.unit,
    },
});

export class EntityView extends React.Component {
    itemId;

    constructor(props) {
        super(props);

        const state = {
            item: undefined,
            new: false,
        }

        this.loadArticle = this.loadArticle.bind(this);

        this.loadArticle(this.props.match.params.itemId);
        this.state = state;

    }

    loadArticle(id) {
        request.get(this.props.app.host + '/' + this.props.endpoint + '/' + id)
            .set('Authorization', 'Bearer ' + this.props.auth.token)
            .then(res => {
                this.setState({
                    item: res.body,
                });
                this.props.dispatch({type: 'APP_IDLE'});
            })
            .catch(() => {
                this.props.dispatch({type: 'APP_IDLE'});
            });
    }

    render() {
        const {classes} = this.props;
        let form = null;
        if (this.state.item) {
            const formConfiguration = [];
            this.props.editFields.forEach(field => {
                field.defaultValue = this.state.item[field.id];
                formConfiguration.push(field);
            });

            form = (<Form elements={formConfiguration}
                          submit={this.save}
                          cancel={() => {
                              this.props.history.goBack();
                          }}
            />);
        }

        // Render Polygons
        const polygonList = [];
        if (this.state.item) {
            this.state.item.polygons.forEach((polygon, key) => {
                polygonList.push(
                    <ListItem key={key}
                    >
                        <ListItemIcon>
                            <Layers/>
                        </ListItemIcon>
                        <ListItemText primary={polygon.title}/>
                    </ListItem>)
            });
        }

        // Render MapView
        let articleMapView = <></>;
        if (this.state.item && this.state.item.polygons.length > 0) {
            articleMapView = <MapPolygonsView polygons={this.state.item.polygons}/>;
        }

        return (
            <Loading>
                <Grid container spacing={24}>
                    <Grid item xs={3}>
                        <Typography variant="h6" className={classes.headline}>
                            Felder
                        </Typography>
                        {form}
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="h6" className={classes.headline}>
                            zugeordnete Polygone
                        </Typography>
                        <List>
                            {polygonList}
                        </List>
                    </Grid>
                    <Grid item xs={6}>
                        {articleMapView}
                    </Grid>
                </Grid>
            </Loading>
        );
    }
}

export default connectStore(withRouter(withStyles(styles)(EntityView)), {
    article: true,
});
