import React from 'react';
import { withRouter } from 'react-router-dom';
import { Route } from "react-router-dom";
import { connectStore } from '../../Store';

import SubMenu from '../../ui/SubMenu';
import SecondMenuLayout from '../../ui/layout/SecondMenuLayout';
import PolygonsTable from './polygons/PolygonsList';
import PlacesTable from './places/PlacesList';
import PolygonEditView from './polygons/PolygonEditView';
import { PlaceEditView } from './places/PlaceEditView';
import NominatimsList from './nominatims/NominatimsList';
import ExportView from './export/ExportView';

export class RegioSection extends React.Component {

  constructor(props) {
    super(props);
    
    this.classes = props.classes;

    this.menu = [
        {
            label: "Polygone",
            link: "/regio/polygons",
        },
        {
            label: "Orte",
            link: "/regio/places",
        },
        {
            label: "Verwaltungsebenen",
            link: "/regio/nominatims",
        },
        {
            label: "Export",
            link: "/regio/export",
        },
    ]
  }

  componentWillMount() {

  }

  render() {             
    return (
        <SecondMenuLayout
            menu={
                <SubMenu menu={this.menu} />
            }
        >
            <Route path="/regio/polygons" exact={true} component={PolygonsTable} />
            <Route path="/regio/polygons/:polygonId" exact={true} component={PolygonEditView} />
            <Route path="/regio/places" exact={true} component={PlacesTable} />
            <Route path="/regio/places/:itemId" exact={true} component={PlaceEditView} />
            <Route path="/regio/nominatims" exact={false} component={NominatimsList} />
            <Route path="/regio/export" exact={false} component={ExportView} />
        </SecondMenuLayout>
    );
  }
};

export default withRouter(connectStore(RegioSection), {
    
});