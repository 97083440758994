import React from 'react';
import {withRouter} from 'react-router';
import {connectStore} from '../../Store';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import {withStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Loading from "../../ui/Loading";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Group from "@material-ui/icons/Group";
import ListItemText from "@material-ui/core/ListItemText";
import {Button} from "@material-ui/core";
import Form from "../../ui/form/Form";
import CardActions from '@material-ui/core/CardActions';

const request = require('superagent-use')(require('superagent'))
request.use(require('superagent-verbose-errors'))

const styles = theme => ({
    card: {
        overflow: "visible",
        margin: 20,
        justifyContent: "space-between"
    },
    cardActions: {
        marginTop: 40,
    }
});

export class UserInfo extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;

        const state = {
            user: {},
            formError: '',
            formErrorColor: {"color": "black"}
        }

        this.loadUser = this.loadUser.bind(this);
        this.regenApiKey = this.regenApiKey.bind(this);
        this.updatePassword = this.updatePassword.bind(this);
        this.loadUser();
        this.state = state;
    }

    loadUser() {
        request.get(this.props.app.host + '/users/info')
            .set('Authorization', 'Bearer ' + this.props.auth.token)
            .then(res => {
                this.setState({
                    user: res.body,
                });
                this.props.dispatch({type: 'APP_IDLE'});
            })
            .catch(() => {
                this.props.dispatch({type: 'APP_IDLE'});
            });
    }

    regenApiKey() {
        request.post(this.props.app.host + '/users/rotateapikey')
            .set('Authorization', 'Bearer ' + this.props.auth.token)
            .then(res => {
                this.setState({
                    user: res.body,
                });
                this.props.dispatch({type: 'APP_IDLE'});
            })
            .catch(() => {
                this.props.dispatch({type: 'APP_IDLE'});
            });
    }

    updatePassword(data) {
        const values = this.state;
        values.formError = '';
        values.formErrorColor =  {"color": "black"};
        request.put(this.props.app.host + '/users/password')
            .set('Authorization', 'Bearer ' + this.props.auth.token)
            .send(data)
            .then(res => {
                this.setState({
                    user: res.body,
                });
                this.props.dispatch({type: 'APP_IDLE'});
                values.formError = 'Das Passwort wurde erfolgreich geändert.'
                this.setState(values);
            })
            .catch((error) => {
                if(error.status === 401) {
                    values.formErrorColor =  {"color": "red"};
                    values.formError = 'Das alte Passwort scheint nicht zu stimmen.'
                }
                this.props.dispatch({type: 'APP_IDLE'});
                this.setState(values);
            });
    }

    render() {
        const {classes} = this.props;
        // No view till user is loaded
        if (typeof this.state.user.email === "undefined") {
            return(<Loading></Loading>);
        }

        const formConfiguration = [
            {
                id: 'email',
                type: 'text',
                disabled: true,
                defaultValue: this.state.user.email,
                label: 'Username',
                autocomplete: 'username',
            },
            {
                id: 'old_password',
                type: 'password',
                validate: (value) => {
                    return (value !== "" && typeof value !== "undefined");
                },
                label: 'Altes Passwort',
                errorText: 'Bitte das alte Passwort eingeben',
                autocomplete: 'current-password',
            },
            {
                id: 'new_password',
                type: 'password',
                validate: (value) => {
                    return (value !== "" && typeof value !== "undefined");
                },
                label: 'Neues Passwort',
                errorText: 'Das neue Passwort darf nicht leer sein.',
                autocomplete: 'new-password',
            },
            {
                id: 'new_password_again',
                type: 'password',
                validate: (value, values) => {
                    return (value !== "") && (value === values['new_password']);
                },
                label: 'Neues Passwort wiederholen',
                errorText: 'Die neuen Passwörter müssen gleich sein.',
                autocomplete: 'new-password',
            },
            {
                id: 'submit',
                type: 'submit',
                label: 'Passwort ändern',
            },
            {
                id: 'cancel',
                type: 'cancel',
                label: 'Abbrechen',
            }
        ];

        const scopesList = [];
        if (this.state.user.scope) {
            this.state.user.scope.forEach((scope, key) => {
                scopesList.push(
                    <ListItem key={scope}
                    >
                        <ListItemIcon>
                            <Group/>
                        </ListItemIcon>
                        <ListItemText primary={scope}>{scope}</ListItemText>
                    </ListItem>)
            });
        }
        return (<Loading>
                <Grid container spacing={24} alignItems="stretch">
                    <Grid item xs={3} component={Card} className={classes.card}>
                            <CardContent>
                                <Typography gutterBottom variant="h4" component="h5">Benutzer Details</Typography>
                                <Typography gutterBottom component="p"><b>Username: </b> {this.state.user.name}</Typography>
                                <Typography gutterBottom component="p"><b>E-Mail: </b> {this.state.user.email}</Typography>
                                <Typography gutterBottom component="p">
                                    <b>Api Key:</b> {this.state.user.token || ''}
                                </Typography>
                                <Typography gutterBottom component="p"><b>Erstellt::</b> {this.state.user.createdAt}</Typography>
                                <Typography gutterBottom component="p"><b>Letztes Update:</b> {this.state.user.updatedAt}</Typography>
                                <CardActions className={classes.cardActions}>
                                    <Button color="primary" variant="contained" onClick={this.regenApiKey} fullWidth={true}>API Key erneuern</Button>
                                </CardActions>
                            </CardContent>
                    </Grid>
                    <Grid item xs={3} component={Card} className={classes.card}>
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h5">
                                    Passwort ändern
                                </Typography>
                                <Typography gutterBottom component="p" style={this.state.formErrorColor}>
                                    {this.state.formError}
                                </Typography>
                                <Form elements={formConfiguration}
                                      submit={this.updatePassword}
                                      cancel={() => {
                                          this.props.history.goBack();
                                      }}
                                />
                            </CardContent>
                    </Grid>
                    <Grid item xs={3} component={Card} className={classes.card}>
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h5">
                                    Benutzergruppen
                                </Typography>
                                <List>
                                    {scopesList}
                                </List>
                            </CardContent>
                    </Grid>
                </Grid>
            </Loading>
        )
    }
}

export default connectStore(withRouter(withStyles(styles)(UserInfo)), {});
