import React from 'react';
import {withRouter} from 'react-router';
import {connectStore} from '../../Store';
import {withStyles} from '@material-ui/core/styles';
import {Paper} from '@material-ui/core';
import EntityViewMap from "../../ui/EntityViewMap";
import {ArticleView} from "./ArticleView";

const styles = theme => ({
    paperWrap: {
        padding: 0,
        boxShadow: 'none',
    },
});

export class ArticleViewMap extends ArticleView {
    render() {
        const {classes} = this.props;

        return (
            <Paper className={classes.paperWrap}>
                <Paper>
                    <EntityViewMap fullview={true} endpoint="article" params={this.props.params}/>
                </Paper>
            </Paper>
        );
    }
}

export default connectStore(withRouter(withStyles(styles)(ArticleViewMap)), {
    article: true,
});
