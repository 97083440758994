import React from 'react';
import {withRouter} from 'react-router';
import {withStyles} from '@material-ui/core/styles';
import request from 'superagent';
import {connectStore} from '../Store';
import Isbn from 'isbn-utils';
import Loading from '../ui/Loading';
import Form from '../ui/form/Form';
import MapPolygonsView from '../ui/MapPolygonsView';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Layers from '@material-ui/icons/Layers';
import Delete from '@material-ui/icons/Delete';
import Grid from '@material-ui/core/Grid';
import {Button, Typography} from '@material-ui/core';
import _ from 'lodash';

const styles = theme => ({
    headline: {
        marginLeft: theme.spacing.unit,
    },
});

export class EntityEdit extends React.Component {
    itemId;

    constructor(props) {
        super(props);

        const state = {
            item: undefined,
            new: false,
        }

        this.loadArticle = this.loadArticle.bind(this);
        this.removePolygon = this.removePolygon.bind(this);
        this.save = this.save.bind(this);

        if (this.props.match.params.itemId === 'new') {
            state.new = true;

            let newEntity = {};
            this.props.editFields.forEach(field => {
                newEntity[field.id] = "";
            });
            // Polygons
            newEntity.polygons = [];
            state.item = newEntity;

        } else {
            this.loadArticle(this.props.match.params.itemId);
        }

        this.state = state;

    }

    loadArticle(id) {
        request.get(this.props.app.host + '/' + this.props.endpoint + '/' + id)
            .set('Authorization', 'Bearer ' + this.props.auth.token)
            .then(res => {
                this.setState({
                    item: res.body,
                });
                this.props.dispatch({type: 'APP_IDLE'});
            })
            .catch(() => {
                this.props.dispatch({type: 'APP_IDLE'});
            });
    }

    removePolygon(polygon) {
        let polygonList = [...this.state.item.polygons];

        let pos = -1;
        polygonList.forEach((polygonInList, key) => {
            if (polygonInList._id === polygon._id) {
                pos = key;
            }
        });

        if (pos >= 0) {
            polygonList.splice(pos, 1);
            this.setState({
                item: Object.assign({}, this.state.item, {polygons: polygonList}),
            });
        }
    }

    save(values) {
        let data = Object.assign({}, values);
        data._id = undefined;
        data.createdAt = undefined;
        data.updatedAt = undefined;
        data.cancel = undefined;
        data.submit = undefined;
        data.__v = undefined;
        // fix isbn
        if (data.hasOwnProperty('isbn')) {
            data.isbn = Isbn.asIsbn13(data.isbn);
        }

        // Append Polygons
        data.polygons = [];
        this.state.item.polygons.forEach(polygon => {
            data.polygons.push(polygon._id);
        })

        let newRequest = request.put(this.props.app.host + '/' + this.props.endpoint + '/' + this.state.item._id);
        if (this.state.new) {
            newRequest = request.post(this.props.app.host + '/' + this.props.endpoint);
        }

        newRequest.set('Authorization', 'Bearer ' + this.props.auth.token)
            .send(data)
            .then(() => {
                this.props.dispatch({type: 'NEW_SNACKBAR_MASSAGE', message: 'Abgespeichert!'});
                this.props.history.goBack();
            })
            .catch((err) => {
                this.props.dispatch({type: 'NEW_SNACKBAR_MASSAGE', message: 'Das hat leider nicht geklappt!'});
                console.error("REQUEST FAILED", err);
            });
    }


    render() {
        const {classes} = this.props;
        let form = null;
        if (this.state.item) {
            const formConfiguration = [];
            this.props.editFields.forEach(field => {
                field.defaultValue = this.state.item[field.id];
                formConfiguration.push(field);
            });

            form = (<Form elements={formConfiguration}
                          submit={this.save}
                          cancel={() => {
                              this.props.history.goBack();
                          }}
            />);
        }

        // Render Polygons
        const polygonList = [];
        if (this.state.item) {
            this.state.item.polygons.forEach((polygon, key) => {
                let titles = [polygon.title];
                titles = titles.concat(polygon.places.map((place) => {return place.title}));
                titles = titles.concat(polygon.nominatims.map((nominatim) => {return nominatim.title}));
                titles = _.uniq(titles);
                polygonList.push(
                    <ListItem key={key}
                    >
                        <ListItemIcon>
                            <Layers/>
                        </ListItemIcon>
                        <ListItemText primary={titles.join(', ')}/>
                        <Button onClick={() => {
                            this.removePolygon(polygon)
                        }}>
                            <Delete/>
                        </Button>
                    </ListItem>)
            });
        }

        // Render MapView
        let articleMapView = <></>;
        if (this.state.item && this.state.item.polygons.length > 0) {
            articleMapView = <MapPolygonsView polygons={this.state.item.polygons}/>;
        }

        return (
            <Loading>
                <Grid container spacing={24}>
                    <Grid item xs={3}>
                        <Typography variant="h6" className={classes.headline}>
                            Felder
                        </Typography>
                        {form}
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="h6" className={classes.headline}>
                            zugeordnete Polygone
                        </Typography>
                        <List>
                            {polygonList}
                        </List>
                    </Grid>
                    <Grid item xs={6}>
                        {articleMapView}
                    </Grid>
                </Grid>
            </Loading>
        );
    }
}

export default connectStore(withRouter(withStyles(styles)(EntityEdit)), {
    article: true,
});
