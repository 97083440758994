import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from "react-router-dom";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import App from './components/App';
import { store } from './components/Store';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
      primary: {
        main: "#FF8D02",
        contrastText: "#FFFFFF",
      },
      secondary: {
        main: "#8EBAD7",
      },
      text: {
          disabled: "rgba(0, 0, 0, 0.7)"
      }
    },
});

ReactDOM.render((
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <BrowserRouter>
          <App />
        </BrowserRouter>
    </MuiThemeProvider>
  </Provider>
), document.getElementById('root'))
