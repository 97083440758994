import React from 'react';
import {withRouter} from 'react-router';
import {connectStore} from '../../Store';
import {withStyles} from '@material-ui/core/styles';
import Isbn from 'isbn-utils';
import EntityEdit from '../../ui/EntityEdit';
import {Paper, Typography} from '@material-ui/core';
import DeleteIcon from "@material-ui/icons/Delete";
import Fab from "@material-ui/core/Fab";
import Modal from '@material-ui/core/Modal';
import Form from "../../ui/form/Form";
import request from 'superagent';

const styles = theme => ({
    search: {
        width: '100%',
    },
    paperWrap: {
        padding: theme.spacing.unit * 2,
        boxShadow: 'none',
    },
    table: {
        marginTop: theme.spacing.unit * 3,
    },
    fab: {
        position: "absolute",
        top: theme.spacing.unit * 10,
        right: theme.spacing.unit * 2,
    },
    paper: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 2,
    },
});


export class ArticleEditView extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;

        this.state = {
            new: false,
            modalOpen: false,
        }

        this.handleModalClose = this.handleModalClose.bind(this);
        this.handleModalOpen = this.handleModalOpen.bind(this);
        this.deleteArticle = this.deleteArticle.bind(this);
    }

    handleModalClose() {
        const stateValues = this.state;
        stateValues.modalOpen = false;
        this.setState(stateValues);
    }

    handleModalOpen() {
        const stateValues = this.state;
        stateValues.modalOpen = true;
        this.setState(stateValues);
    }

    deleteArticle() {
        const newRequest = request.delete(this.props.app.host + '/article/' + this.props.match.params.itemId);
        newRequest.set('Authorization', 'Bearer ' + this.props.auth.token)
            .send({})
            .then(() => {
                this.props.dispatch({type: 'NEW_SNACKBAR_MASSAGE', message: 'Produkt gelöscht!'});
                this.props.history.goBack();
            })
            .catch((err) => {
                this.props.dispatch({type: 'NEW_SNACKBAR_MASSAGE', message: 'Das hat leider nicht geklappt!'});
                console.error("REQUEST FAILED", err);
            });
    }

    render() {
        const {classes} = this.props;
        const modalFormConfiguration = [
            {
                id: 'submit',
                type: 'submit',
                label: 'Produkt endgültig löschen',
            },
            {
                id: 'cancel',
                type: 'cancel',
                label: 'Abbrechen',
            }
        ];

        const body = (
            <div style={styles.modal} className={classes.paper}>
                <h2 id="simple-modal-title">Produkt löschen</h2>
                <p id="simple-modal-description">
                    Sind Sie sicher das sie dieses Produkt löschen wollen?
                </p>
                <Form elements={modalFormConfiguration}
                      submit={this.deleteArticle}
                      cancel={(e) => {
                          this.handleModalClose();
                      }}
                />
            </div>
        );

        let deleteDialog = (
            <>
                <Fab color="primary"
                     variant="extended"
                     aria-label="delete"
                     className={classes.fab}
                     onClick={this.handleModalOpen}
                >
                    <DeleteIcon/>
                    Produkt löschen
                </Fab>
                <Modal
                    open={this.state.modalOpen}
                    onClose={this.handleModalClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {body}
                </Modal>
            </>
        )

        // No delete Dialog if new Product
        if (this.props.match.params.itemId === 'new') {
            deleteDialog = (<></>);
        }

        const formConfiguration = [
            {
                id: 'title',
                type: 'text',
                validate: (value) => {
                    return (value !== "");
                },
                label: 'Titel',
            },
            {
                id: 'isbn',
                type: 'text',
                validate: (value) => {
                    const isbn = Isbn.parse(value);
                    return (isbn) ? isbn.isIsbn13() : false;
                },
                label: 'ISBN',
                errorText: 'Bitte eine gültige ISBN eingeben!',
            },
            {
                id: 'sku',
                type: 'text',
                validate: (value) => {
                    return (value !== "");
                },
                label: 'Artikelnummer',
                errorText: 'Bitte eine Artikelnummer eingeben!',
            },
            {
                id: 'active',
                type: 'checkbox',
                label: 'Wird im Export berücksichtigt',
                default: true,
            },
            {
                id: 'createdAt',
                type: 'disabled',
                label: 'Erstellt am',
            },
            {
                id: 'updatedAt',
                type: 'disabled',
                label: 'Zuletzt geändert am',
            },
            {
                id: 'submit',
                type: 'submit',
                label: 'Speichern',
            },
            {
                id: 'cancel',
                type: 'cancel',
                label: 'Abbrechen',
            }
        ];
        return (
            <Paper className={classes.paperWrap}>
                <Typography variant="h3" gutterBottom>
                    Produkt {(this.props.match.params.itemId === 'new') ? " erstellen" : " bearbeiten"}
                </Typography>
                {deleteDialog}
                <Paper>
                    <EntityEdit editFields={formConfiguration} endpoint="article" params={this.props.params}/>
                </Paper>
            </Paper>
        );
    }
}

export default connectStore(withRouter(withStyles(styles)(ArticleEditView)), {
    article: true,
});
