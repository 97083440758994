import React from 'react';
import { withRouter } from 'react-router';
import { connectStore } from '../../../Store';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import axios from "axios";
import SidebarRight from "../../../ui/layout/SidebarRight";
import Typography from '@material-ui/core/Typography';
import EnhancedTable from '../../../ui/table/EnhancedTable';
import NominatimView from './NominatimView';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import { TextField, InputAdornment } from '@material-ui/core';
import NominatimsActionsMenu from "./NominatimsActionsMenu";

const styles = theme => ({
    search: {
        width: '100%',
    },
    table: {
        marginTop: theme.spacing.unit * 3,
    },
    fab: {
        position: "absolute",
        top: theme.spacing.unit * 2,
        right: theme.spacing.unit * 2,
    }
});


export class NominatimsTable extends React.Component {

    tableSettingKey = "nominatimsAll";

    constructor(props) {
        super(props);

        this.loadData = this.loadData.bind(this);
        this.setSearch = this.setSearch.bind(this);
    }

    async loadData() {
        let tableSetting = this.props.tableSettings[this.tableSettingKey];
        if (!tableSetting) {
            return;
        }
        let path = this.props.app.host + '/nominatim?limit=' + tableSetting.fetch.rowsPerPage + '&skip=' + (tableSetting.fetch.page) * tableSetting.fetch.rowsPerPage  + "&sortorder=" + tableSetting.fetch.order + "&sortfield=" + tableSetting.fetch.orderBy;

        if(tableSetting.fetch.search && tableSetting.fetch.search.length >= 3) { 
            path += '&search=' + tableSetting.fetch.search;
        }


        let response = await axios({
            method: 'get',
            url: path,
        });
        
        if (response) {
            this.props.dispatch({
                type: 'TABLESETTING_SET',
                data: {
                    id: this.tableSettingKey,
                    rows: response.data.data,
                    filteredRows: response.data.data,
                    rowsLength: response.data.length
                },
            });
        }

    }

    setSearch(e) {
        this.props.dispatch({
            type: 'TABLESETTING_SET',
            data: {
                id: this.tableSettingKey,
                fetch: {
                  search: e.target.value
                }
            },
        });
    }

    render() {

        const { classes } = this.props;
        let tableSetting = this.props.tableSettings[this.tableSettingKey];

        let sidebarContent = null;
        if (this.props.tableSettings[this.tableSettingKey] && this.props.tableSettings[this.tableSettingKey].selected.length > 0) {
            sidebarContent = <NominatimView id={this.props.tableSettings[this.tableSettingKey].selected[0]} />;
        }

        return <SidebarRight sidebar={sidebarContent}>

                <Typography variant="h3" gutterBottom>Verwaltungsebenen</Typography>

                <Fab color="primary" 
                    variant="extended" 
                    aria-label="Add" 
                    className={classes.fab}
                    onClick={() => {
                        this.props.history.push("/regio/place/new");
                    }}
                >
                    <AddIcon className={classes.extendedIcon} />
                    Neuer Ort
                </Fab>

                {this.props.tableSettings[this.tableSettingKey] && <Card>
                    <CardContent>
                        <TextField
                            className={classes.search}
                            id="input-with-icon-textfield"
                            onChange={this.setSearch}
                            value={tableSetting.fetch.search}
                            InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                            }}
                        />
                    </CardContent>
                </Card>}

                <Paper className={classes.table}>
                    <EnhancedTable
                        tableSettingsKey={this.tableSettingKey}
                        multiSelect={false}
                        tableColumns={[
                            { id: 'title', numeric: false, disablePadding: true, label: 'Titel' },
                            { id: 'osmid', numeric: false, disablePadding: true, label: 'OSM' },
                        ]}
                        actions={item => (<NominatimsActionsMenu nominatim={item} />)}
                        dataFetch={this.loadData}
                    />
                </Paper>

        </SidebarRight>
    }
}

export default connectStore(withRouter(withStyles(styles)(NominatimsTable)), {
    article: true,
    tableSettings: true,
});
