import React from 'react';
import {withRouter} from 'react-router';
import request from 'superagent';
import {connectStore} from '../../../Store';
import Loading from '../../../ui/Loading';
import PolygonEdit from '../../../ui/PolygonEdit';
import {Paper, Typography} from '@material-ui/core';

export class PolygonEditView extends React.Component {
    constructor(props) {
        super(props);

        const state = {
            polygon: undefined,
            new: false,
        }

        this.loadPolygon = this.loadPolygon.bind(this);

        if (this.props.match.params.polygonId === 'new') {
            state.new = true;
        } else {
            this.loadPolygon(this.props.match.params.polygonId);
        }

        this.state = state;

    }

    loadPolygon(id) {
        request.get(this.props.app.host + '/polygon/' + id)
            .set('Authorization', 'Bearer ' + this.props.auth.token)
            .then(res => {
                this.setState({
                    polygon: res.body,
                });
                this.props.dispatch({type: 'APP_IDLE'});
            })
            .catch(() => {
                this.props.dispatch({type: 'APP_IDLE'});
            });
    }


    render() {

        let polygonEdit = null;
        if (this.state.polygon) {
            polygonEdit = (
                <PolygonEdit polygon={this.state.polygon}/>
            );
        }
        if (this.state.new) {
            polygonEdit = (
                <PolygonEdit new={this.state.new}/>
            );
        }

        return (
            <Loading>
                <Typography variant="h3" gutterBottom>
                    Polygon {(this.props.match.params.polygonId === 'new') ? " erstellen" : " bearbeiten"}
                </Typography>
                <Paper style={{minHeight: 600}}>
                    {polygonEdit}
                </Paper>


            </Loading>
        );
    }
}

export default connectStore(withRouter(PolygonEditView), {});
