import React from 'react';
import {withRouter} from 'react-router';
import {connectStore} from '../../Store';
import {withStyles} from '@material-ui/core/styles';
import {Paper} from '@material-ui/core';
import EntityView from "../../ui/EntityView";

const styles = theme => ({
    paperWrap: {
        padding: 0,
        boxShadow: 'none',
    },
});


export class ArticleView extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    render() {
        const {classes} = this.props;

        const formConfiguration = [
            {
                id: 'title',
                type: 'text',
                disabled: true,
                label: 'Titel',
            },
            {
                id: 'isbn',
                type: 'text',
                disabled: true,
                label: 'ISBN',
            },
            {
                id: 'sku',
                type: 'text',
                disabled: true,
                label: 'Artikelnummer',
            },
            {
                id: 'active',
                type: 'checkbox',
                disabled: true,
                label: 'Wird im Export berücksichtigt',
            },
            {
                id: 'createdAt',
                type: 'disabled',
                label: 'Erstellt am',
            },
            {
                id: 'updatedAt',
                type: 'disabled',
                label: 'Zuletzt geändert am',
            },
        ];
        return (
            <Paper className={classes.paperWrap}>
                <Paper>
                    <EntityView editFields={formConfiguration} endpoint="article" params={this.props.params}/>
                </Paper>
            </Paper>
        );
    }
}

export default connectStore(withRouter(withStyles(styles)(ArticleView)), {
    article: true,
});
