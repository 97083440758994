import React from 'react';
import PropTypes from 'prop-types';
import {connectStore} from '../../Store';
import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import EnhancedTableHead from './EnhancedTableHead';


const styles = () => ({
    table: {
        minWidth: 1020,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
});

export class EnhancedTable extends React.Component {
    lastState = {};

    constructor(props) {
        super(props);

        this.tableSetting = {
            id: this.props.tableSettingsKey,
            rows: [],
            rowsLength: 0,
            filteredRows: [],
            selected: [],
            fetch: {
                order: 'asc',
                orderBy: 'title',
                page: 0,
                rowsPerPage: 50,
                search: "",
            },
            tableColumns: props.tableColumns,
        };

        this.props.dispatch({
            type: 'TABLESETTING_SET',
            data: this.tableSetting,
        });

        this.setSelect = this.setSelect.bind(this);
        this.isSelected = this.isSelected.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleSelectAllClick = this.handleSelectAllClick.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.tableSettings[this.props.tableSettingsKey] && JSON.stringify(this.props.tableSettings[this.props.tableSettingsKey].fetch) !== JSON.stringify(this.lastState)) {
            this.lastState = this.props.tableSettings[this.props.tableSettingsKey].fetch;
            this.props.dataFetch();
        }
    }


    /**
     * Sets selected Item
     */
    setSelect(item) {
        let tableSetting = this.props.tableSettings[this.props.tableSettingsKey];
        if (this.isSelected(item._id)) {
            tableSetting.selected.splice(tableSetting.selected.indexOf(item._id), 1);
            this.props.dispatch({
                type: 'TABLESETTING_SET',
                data: {
                    id: this.props.tableSettingsKey,
                    selected: tableSetting.selected
                },
            });
        } else {
            let selection = [item._id];
            if (this.props.multiSelect) {
                selection = [...tableSetting.selected, item._id];
            }

            this.props.dispatch({
                type: 'TABLESETTING_SET',
                data: {
                    id: this.props.tableSettingsKey,
                    selected: selection,
                },
            });
        }
    }

    isSelected(id) {
        return this.props.tableSettings[this.props.tableSettingsKey].selected.indexOf(id) !== -1;
    }


    handleChangePage = (event, page) => {
        this.props.dispatch({
            type: 'TABLESETTING_SET',
            data: {id: this.props.tableSettingsKey, fetch: {page: page}},
        });
    };

    handleChangeRowsPerPage = event => {
        this.props.dispatch({
            type: 'TABLESETTING_SET',
            data: {id: this.props.tableSettingsKey, fetch: {rowsPerPage: event.target.value}},
        });
    };

    handleSelectAllClick = () => {
        let selection = [];
        if (this.props.tableSettings[this.props.tableSettingsKey].selected.length > 0) {
            // DESELECT
        } else {
            // SELECT ALL
            if (this.props.multiSelect) {
                selection = this.props.tableSettings[this.props.tableSettingsKey].rows.map(item => item._id);
            }
        }

        this.props.dispatch({
            type: 'TABLESETTING_SET',
            data: {
                id: this.props.tableSettingsKey,
                selected: selection,
            },
        });
    }

    render() {

        if (!this.props.tableSettings[this.props.tableSettingsKey]) {
            return <div>Loading</div>
        }

        const {classes} = this.props;
        let tableSetting = this.props.tableSettings[this.props.tableSettingsKey];
        const {rowsPerPage, page} = this.props.tableSettings[this.props.tableSettingsKey].fetch;

        return <div>
            <div className={classes.tableWrapper}>
                <Table className={classes.table} aria-labelledby="tableTitle">
                    <EnhancedTableHead
                        onSelectAllClick={this.handleSelectAllClick}
                        onRequestSort={() => {
                        }}
                        columns={tableSetting.tableColumns}
                        tableSettingsKey={this.props.tableSettingsKey}
                        actions={this.props.actions}
                    />

                    <TableBody>
                        {tableSetting.rows.map(n => (
                            <TableRow
                                hover
                                key={n._id}
                                onClick={() => {
                                    this.setSelect(n);
                                }}
                                role="checkbox"
                                aria-checked={false}
                                tabIndex={-1}

                                selected={this.isSelected(n._id)}
                            >
                                <TableCell padding="checkbox">
                                    <Checkbox checked={this.isSelected(n._id)}/> {n.id}
                                </TableCell>
                                {tableSetting.tableColumns.map(column => {
                                    if (typeof n[column.id] === 'boolean') {
                                        n[column.id] = n[column.id] ? 'ja' : 'nein';
                                    }
                                    if (column.disablePadding) {
                                        return (
                                            <TableCell key={column.id} component="th" scope="row" padding="none">
                                                {n[column.id]}
                                            </TableCell>
                                        );
                                    } else {
                                        return (
                                            <TableCell key={column.id} numeric={column.numeric}>
                                                {n[column.id]}
                                            </TableCell>
                                        );
                                    }
                                })}
                                {this.props.actions && <TableCell padding="none" onClick={event => {
                                    event.stopPropagation();
                                }}>
                                    {this.props.actions(n)}
                                </TableCell>}
                            </TableRow>
                        ))}
                    </TableBody>

                </Table>
            </div>
            <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={tableSetting.rowsLength}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                    'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                    'aria-label': 'Next Page',
                }}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
        </div>;
    }
}

EnhancedTable.propTypes = {
    tableSettingsKey: PropTypes.string.isRequired,
    tableColumns: PropTypes.array.isRequired,
    dataFetch: PropTypes.func.isRequired,
    actions: PropTypes.func,
};

export default connectStore(withStyles(styles)(EnhancedTable), {
    article: true,
    tableSettings: true,
});
