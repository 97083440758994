import AuthStateRecord from "./auth.store";
import axios from "axios";

const authReducer = function (state, action) {
    if (state === undefined) {
        state = new AuthStateRecord({
            token: localStorage.token || '',
            apiToken: localStorage.apiToken || '',
            scopes: localStorage.scopes ? localStorage.scopes.split(',') : [],
            loggedIn: !!localStorage.token
        });

        if (localStorage.token) {
            axios.defaults.headers.common['Authorization'] = 'Bearer '+ localStorage.token;
        }
    }

    switch (action.type) {
        case 'AUTH_LOGGED_IN':
            if (action.remember) {
                localStorage.setItem("token", action.token);
                localStorage.setItem("apiToken", action.apiToken);
                localStorage.setItem("scopes", action.scopes);
            }
            
            state = state.merge({
                loggedIn: true,
                token: action.token,
                apiToken: action.apiToken,
                scopes: action.scopes,
            });

            axios.defaults.headers.common['Authorization'] = 'Bearer '+ action.token;

            break;
        case 'AUTH_LOGGED_OUT':
            state = state.clear();
            localStorage.removeItem("token");
            localStorage.removeItem("apiToken");
            localStorage.removeItem("scopes");
            break;
        case 'AUTH_TOKEN_CHECKED':
            if (!action.result)
                state = state.clear();
            break;
        default:
            break;
    }
    return state;
}

export default authReducer;
