import React from 'react';
import { withRouter } from 'react-router';
import { connectStore } from '../Store';

import CircularProgress from '@material-ui/core/CircularProgress';

export class Loading extends React.Component {

    styles = {
        main: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
        },
        loading: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
        },
    };

    /* constructor(props) {
        super(props);
    }*/

    render() {
        if(this.props.app.busy) {
            return (
                <div style={{ ...this.styles.loading }}>
                    <CircularProgress size={80} thickness={5} />
                </div>
            );
        } else {
            return (
                <div style={{ ...this.styles.main }}>
                    {this.props.children}
                </div>
            );
        }
    }
};

export default connectStore(withRouter(Loading), {
});