import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from "@material-ui/core/FormControlLabel";

export class Form extends React.Component {
    elements;

    constructor(props) {
        super(props);
        this.elements = this.props.elements;
        // generate State
        const state = {
            values: {},
            errors: {},
        }

        this.elements.forEach((elem) => {
            state.values[elem.id] = elem.defaultValue;
            state.errors[elem.id] = "";
        });

        this.getElement = this.getElement.bind(this);
        this.changeField = this.changeField.bind(this);
        this.validate = this.validate.bind(this);
        this.submit = this.submit.bind(this);
        this.cancel = this.cancel.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);

        this.state = state;
    }


    getElement() {

    }

    changeField(elem, value) {
        let values = {};
        values[elem.id] = value;

        this.setState({
            values: Object.assign({}, this.state.values, values),
        });
    }

    validate() {
        let errors = {};

        this.elements.forEach(elem => {
            if (elem.hasOwnProperty('validate') && typeof elem.validate === 'function' && elem.validate(this.state.values[elem.id], this.state.values) === false) {
                // Not valid
                errors[elem.id] = elem.errorText;
            } else {
                // valid
                errors[elem.id] = "";
            }
        });

        this.setState({
            errors: errors,
        });

        return (Object.keys(errors).length > 0);
    }

    submit() {
        // clear empty values from Object
        let output = {};
        let elements = {};
        this.elements.forEach((elem)  => {
          elements[elem.id] = elem;
        });

        Object.keys(this.state.values).forEach(key => {
            if (typeof this.state.values[key] === 'string' && this.state.values[key] !== "") {
                output[key] = this.state.values[key];
            } else if (elements[key].type === 'checkbox') {
                output[key] = typeof this.state.values[key] === 'boolean' ? this.state.values[key]  : elements[key].default;
            }
        });

        if (this.validate()) {
            this.props.submit(output);
        }
    }

    handleCheckboxChange = (event) => {
        const values = this.state.values;
        values[event.target.name] = event.target.checked;
        this.setState({
            values: Object.assign({}, this.state.values, values),
        });
    };

    cancel(e) {
        this.props.cancel(e);
    }


    render() {
        const elements = [];
        this.elements.forEach((elem, key) => {
            switch (elem.type) {
                case 'date':
                    break;
                case 'submit':
                    elements.push(
                        <Button key={key} color="primary" variant="contained" fullWidth={true}
                                onClick={this.submit}>{elem.label}</Button>
                    );
                    break;
                case 'cancel':
                    elements.push(
                        <Button key={key} fullWidth={true} onClick={this.cancel}>{elem.label}</Button>
                    );
                    break;
                case 'checkbox':
                    elements.push(
                        <FormControlLabel
                            key={key + 'label'}
                            control={<Checkbox
                                key={key}
                                name={elem.id}
                                disabled={elem.disabled || false}
                                defaultChecked={typeof this.state.values[elem.id] === 'boolean' ? this.state.values[elem.id] : elem.default}
                                onChange={this.handleCheckboxChange}
                            />}
                            style={{width: '100%'}}
                            label={elem.label}
                        />
                    );
                    break;
                case 'disabled':
                    elements.push(
                        <TextField
                            key={key}
                            label={elem.label}
                            fullWidth={true}
                            disabled={true}
                            value={this.state.values[elem.id]}
                            style={{marginTop: 10, margimBottom: 30}}
                        />
                    );
                    break;
                default:
                    elements.push(
                        <TextField
                            key={key}
                            label={elem.label}
                            fullWidth={true}
                            onChange={(e) => {
                                this.changeField(elem, e.target.value)
                            }}
                            disabled={elem.disabled || false}
                            defaultValue={this.state.values[elem.id]}
                            helperText={this.state.errors[elem.id]}
                            error={!!(this.state.errors[elem.id])}
                            style={{marginTop: 10, margimBottom: 30}}
                            type={elem.type}
                            autoComplete={elem.autocomplete || ''}
                        />
                    );
                    break;
            }
        });

        return (
            <form onSubmit={this.onSubmit} style={{padding: 10}}>
                {elements}
            </form>
        );
    }
}

export default Form;
