import React from 'react';
import { withRouter } from 'react-router';
import { connectStore } from '../../../Store';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import axios from "axios";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AddIcon from '@material-ui/icons/Add';
import CreateIcon from '@material-ui/icons/Create';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';


const styles = theme => ({
    icon: {

    },
    primary: {

    }
});



export class PolygonsActionsMenu extends React.Component {


    state = {
        anchorEl: null,
    };
    
    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };
    
    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    render() {

        const { classes, polygon } = this.props;
        const { anchorEl } = this.state;
        
        return <>
            <IconButton
                aria-owns={anchorEl ? 'simple-menu' : undefined}
                aria-haspopup="true"
                onClick={this.handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={this.handleClose}
            >
                {this.props.article.last._id && <MenuItem onClick={async (e) => {
                        try {
                            let response = await axios({
                                method: 'put',
                                url: this.props.app.host + '/article/' + this.props.article.last._id + "/add-polygon",
                                data: [polygon._id]
                            });
    
                            if (response) {
                                this.props.dispatch({type: 'NEW_SNACKBAR_MASSAGE', message: "Polygon " + polygon.title + " zu Artikel " + this.props.article.last.title + " hinzugefügt"});
                            }
                        } catch (e) {
                            this.props.dispatch({type: 'NEW_SNACKBAR_MASSAGE', message: 'Das hat leider nicht geklappt!'});
                        }

                        this.handleClose();
                    }}
                    >
                    <ListItemIcon className={classes.icon}>
                        <AddIcon />
                    </ListItemIcon>
                    <ListItemText classes={{ primary: classes.primary }} inset primary={"Hinzufügen zum Artikel " + this.props.article.last.title} />
                </MenuItem> }
                
                <MenuItem onClick={(e) => { this.handleClose(); this.props.history.push('/regio/polygons/' + polygon._id); }} >
                    <ListItemIcon className={classes.icon}>
                        <CreateIcon />
                    </ListItemIcon>
                    <ListItemText classes={{ primary: classes.primary }} inset primary="Bearbeiten" />
                </MenuItem>
            </Menu>
        </>;

    }
};

PolygonsActionsMenu.propTypes = {
    polygon: PropTypes.object.isRequired,
  };

export default connectStore(withRouter(withStyles(styles)(PolygonsActionsMenu)), {
    article: true,
});