import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import {connectStore} from '../../Store';

export class EnhancedTableHead extends React.Component {


    createSortHandler = property => event => {
        // this.props.onRequestSort(event, property);
        const order = (this.props.tableSettings[this.props.tableSettingsKey].fetch.order === "asc") ? "desc" : "asc";
        this.props.dispatch({
            type: 'TABLESETTING_SET',
            data: {
                id: this.props.tableSettingsKey,
                fetch: {
                    order,
                    orderBy: property
                }
            },
        });
    };

    render() {
        const {onSelectAllClick, tableSettingsKey} = this.props;

        if (!this.props.tableSettings[tableSettingsKey]) {
            return (<></>);
        }

        const {order, orderBy} = this.props.tableSettings[tableSettingsKey].fetch
        const {rowsLength, selected} = this.props.tableSettings[tableSettingsKey]
        const numSelected = selected.length;

        return (
            <TableHead>
                <TableRow>
                    <TableCell padding="checkbox">
                        <Checkbox
                            indeterminate={numSelected > 0 && numSelected < rowsLength}
                            checked={numSelected === rowsLength}
                            onChange={onSelectAllClick}
                        />
                    </TableCell>
                    {this.props.columns.map(row => {
                        if (row.allowSortBy === false) {
                            return (
                                <TableCell
                                    key={row.id}
                                    numeric={row.numeric}
                                    padding={row.disablePadding ? 'none' : 'default'}
                                    sortDirection={orderBy === row.id ? order : false}
                                >
                                    {row.label}
                                </TableCell>
                            );
                        }
                        return (
                            <TableCell
                                key={row.id}
                                numeric={row.numeric}
                                padding={row.disablePadding ? 'none' : 'default'}
                                sortDirection={orderBy === row.id ? order : false}
                            >
                                <Tooltip
                                    title="Sort"
                                    placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={orderBy === row.id}
                                        direction={order}
                                        onClick={this.createSortHandler(row.id)}
                                    >
                                        {row.label}
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                        );
                    }, this)}
                    {this.props.actions && <TableCell padding="none">
                        Aktionen
                    </TableCell>}
                </TableRow>
            </TableHead>
        );
    }
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number,
    onRequestSort: PropTypes.func.isRequired,
    // onSelectAllClick: PropTypes.func.isRequired,
    tableSettingsKey: PropTypes.string.isRequired,
    columns: PropTypes.array.isRequired,
};

export default connectStore(EnhancedTableHead, {
    tableSettings: true,
});
