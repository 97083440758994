import { createStore, combineReducers } from 'redux';
import { connect } from 'react-redux';
import { routerReducer } from 'react-router-redux'

import appReducer from './redux/app.reducer';
import authReducer from './redux/auth.reducer';
import articleReducer from './redux/article.reducer';
import snackbarReducer from './redux/snackbar.reducer';
import tableSettingsReducer from './redux/tableSettings.reducer';

import _ from 'lodash';

export const store = createStore(
    combineReducers({
        app: appReducer,
        auth: authReducer,
        routing: routerReducer,
        article: articleReducer,
        snackbar: snackbarReducer,
        tableSettings: tableSettingsReducer,
    })
);

export const connectStore = (component, states = {}) => connect((store) => {
    var connections = {
        app: store.app.toJS(),
        auth: store.auth.toJS(),
    };

    _.each(states, (state, name) => {
        if (state && store[name])
            connections[name] = store[name].toJS()
    });
    return connections;
})(component);
