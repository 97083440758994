import { List } from 'immutable';
import SnackbarRecord from "./snackbar.store";

const snackbarReducer = function (state, action) {
    if (state === undefined)
        state = new List();
    switch (action.type) {
        case 'NEW_SNACKBAR_MASSAGE':
            state = state.push(new SnackbarRecord({ message: action.message, open: true }))
            break;
        case 'CLOSE_SNACKBAR_MASSAGE':
            state = state.delete(action.key)
            break;
        default:
            break;
    }
    return state;
}

export default snackbarReducer;