import React from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { connectStore } from '../Store';
import { Map, TileLayer, FeatureGroup } from 'react-leaflet';
import L from 'leaflet';

export class MapPolygonsView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            viewport: {
                center: [ 53.1610859235444 , 8.72314453125], // A random point in germany!
                zoom: 6,
            },
        }
    }

    viewportChange(viewport) {
        
    }

    componentWillUpdate() {
        this._onFeatureGroupReady();
    }

    _onFeatureGroupReady = (reactFGref) => {
        // populate the leaflet FeatureGroup with the geoJson layers
        if(!reactFGref) {
            reactFGref = this._editableFG;
        }
        
        if(!reactFGref) {
            return;
        }
        
        let leafletFG = reactFGref.leafletElement;
        leafletFG.clearLayers();

        
        this.props.polygons.forEach((polygon) => {
            let leafletGeoJSON = new L.GeoJSON(polygon.geoJson);
            leafletGeoJSON.eachLayer( (layer) => {
              leafletFG.addLayer(layer);
            });
        });
        
        
        if(this.refs.map && this.props.polygons.length > 0) {
            const map = this.refs.map.leafletElement;
            map.fitBounds(leafletFG.getBounds());
        }
        // store the ref for future access to content
        this._editableFG = reactFGref;
    }

    render() {
        let minMapHeight = window.innerHeight - 10;
        if (this.props.auth.loggedIn === true) {
            minMapHeight = window.innerHeight - 240;
            if (this.props.fullview === true) {
                minMapHeight = window.innerHeight - 100;
            }
        }

        let mapWidth = 'auto';
        if (this.props.fullview === true) {
            mapWidth = window.innerWidth - 20;
            if (this.props.auth.loggedIn === true) {
                mapWidth = window.innerWidth - 120;
            }
        }

        const styleContent = {
            flex: '1 0 0',
            height: '100%',
        }
        this._onFeatureGroupReady();
        return (
            <div style={{height: '100%', minHeight: minMapHeight, width: mapWidth}}>
                <Map
                    style={styleContent}
                    viewport={this.state.viewport}
                    onViewportChange={this.viewportChange}
                    ref="map"
                    >
                    <TileLayer
                    attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <FeatureGroup  ref={ (reactFGref) => {this._onFeatureGroupReady(reactFGref);} }/>
            </Map>
        </div>);
    }
}

MapPolygonsView.propTypes = {
    polygons: PropTypes.array,
};
  
MapPolygonsView.defaultProps = {
    polygons: [],
};

export default connectStore(withRouter(MapPolygonsView), {});
