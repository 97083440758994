import React from 'react';
import { withRouter } from 'react-router';
import { connectStore } from '../../../Store';
import { withStyles } from '@material-ui/core/styles';
import axios from "axios";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import MapPolygonsView from '../../../ui/MapPolygonsView';



const styles = theme => ({
    card: {
        overflow: "visible",
    },
    media: {
        objectFit: 'contain',
      },
    mapView: {
        height: 500,
        width: '100%',
        marginTop: theme.spacing.unit * 1,
    }
  });


export class PolygonsView extends React.Component {

    state = {
        polygons: [],
        ids: [],
    }

    constructor(props) {
        super(props);

        this.loadPolygon = this.loadPolygon.bind(this);
        this.reload = this.reload.bind(this);
    }

    componentDidMount() {
        this.reload();
    }

    componentDidUpdate() {
        this.reload();
    }

     reload() {
        let polygons = [...this.state.polygons];
        let ids = [...this.state.ids];

        if (JSON.stringify(ids) !== JSON.stringify(this.props.ids)) {

            // DELETE UNSELECTED POYGONS
            ids.forEach((polygonId, polygonIdKey) => {
                const index = this.props.ids.indexOf(polygonId);
                if (index === -1) {
                    ids.splice(polygonIdKey, 1);

                    polygons.forEach((polygon, key) => {
                        if (polygon._id === polygonId) {
                            polygons.splice(key, 1);
                        }
                    });
                }
            });

            // ADD NEW POLYGONS
            this.props.ids.forEach(id => {
                if (ids.indexOf(id) === -1) {
                    ids.push(id);
                    this.loadPolygon(id);
                }
            });
            
            this.setState({
                polygons,
                ids,
            });
        }
    }

    async loadPolygon(id) {

        let path = this.props.app.host + '/polygon/' + id;

        let response = await axios({
            method: 'get',
            url: path,
        });
        
        if (response) {
            this.setState({
                polygons: [...this.state.polygons, response.data],
            });
        }

    }

    render() {
        if (!this.state.polygons.length > 0) {
            return <></>;
        }

        const { classes } = this.props;

        return <Card className={classes.card}>
          <CardContent>
            <Typography component="div" className={classes.mapView}>
                <MapPolygonsView polygons={this.state.polygons} />
            </Typography>    
          </CardContent>
      </Card>;
    }
};

export default connectStore(withRouter(withStyles(styles)(PolygonsView)), {
    tableSettings: true,
});