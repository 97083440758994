import React from 'react';
import {withRouter} from 'react-router';
import {connectStore} from '../../Store';
import {withStyles} from '@material-ui/core/styles';
import {Paper, Typography} from '@material-ui/core';
import DeleteIcon from "@material-ui/icons/Delete";
import Fab from "@material-ui/core/Fab";
import Modal from '@material-ui/core/Modal';
import Form from "../../ui/form/Form";
import Loading from "../../ui/Loading";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";

const request = require('superagent-use')(require('superagent'))
request.use(require('superagent-verbose-errors'))

const styles = theme => ({
    card: {
        overflow: "visible",
        margin: 20,
        justifyContent: "space-between"
    },
    paperWrap: {
        padding: theme.spacing.unit * 2,
        boxShadow: 'none',
    },
    fab: {
        position: "absolute",
        top: theme.spacing.unit * 10,
        right: theme.spacing.unit * 2,
    },
    paper: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 2,
    },
});

export class UserEditView extends React.Component {
    userId;

    constructor(props) {
        super(props);
        this.props = props;

        this.state = {
            new: false,
            modalOpen: false,
            user: {},
            userGroups: [],
        }

        this.handleModalClose = this.handleModalClose.bind(this);
        this.handleModalOpen = this.handleModalOpen.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this.saveUser = this.saveUser.bind(this);
        this.saveUserGroups = this.saveUserGroups.bind(this);
        this.loadUser = this.loadUser.bind(this);
        this.loadUserGroups = this.loadUserGroups.bind(this);
        this.submitSave = this.submitSave.bind(this);

        this.loadUserGroups()

        if (this.props.match.params.userId === 'new') {
            this.state.new = true;

            const newEntity = {};
            newEntity.name = '';
            newEntity.email = '';
            newEntity.password = '';
            newEntity.token = '';
            newEntity.scope = [];

            // Polygons
            newEntity.polygons = [];
            this.state.user = newEntity;

        } else {
            this.loadUser(this.props.match.params.userId);
        }
    }

    handleModalClose() {
        const stateValues = this.state;
        stateValues.modalOpen = false;
        this.setState(stateValues);
    }

    handleModalOpen() {
        const stateValues = this.state;
        stateValues.modalOpen = true;
        this.setState(stateValues);
    }

    loadUser(id) {
        request.get(this.props.app.host + '/users/' + id)
            .set('Authorization', 'Bearer ' + this.props.auth.token)
            .then(res => {
                this.setState({
                    user: res.body,
                });
                this.props.dispatch({type: 'APP_IDLE'});
            })
            .catch(() => {
                this.props.dispatch({type: 'APP_IDLE'});
            });
    }

    loadUserGroups() {
        request.get(this.props.app.host + '/usergroups')
            .set('Authorization', 'Bearer ' + this.props.auth.token)
            .then(res => {
                this.setState({
                    userGroups: res.body,
                });
                this.props.dispatch({type: 'APP_IDLE'});
            })
            .catch(() => {
                this.props.dispatch({type: 'APP_IDLE'});
            });
    }

    saveUser(values) {
        const data = Object.assign({}, values);
        data.scope = this.state.user.scope;

        let newRequest = request.put(this.props.app.host + '/users/' + this.state.user._id);
        if (this.state.new) {
            newRequest = request.post(this.props.app.host + '/users');
        }

        this.submitSave(newRequest, data);
    }

    saveUserGroups(values) {
        console.log("Values", values);
        const scopes = [];
        Object.keys(values).forEach((key) => {
            if (values[key] === true) {
                scopes.push(key);
            }
        });
        const newRequest = request.put(this.props.app.host + '/users/' + this.state.user._id);
        const data = Object.assign({}, this.state.user);
        data.scope = scopes;

        this.submitSave(newRequest, data);
    }

    submitSave(submitRequest, data) {
        delete data._id;
        delete data.createdAt;
        delete data.updatedAt;
        delete data.cancel;
        delete data.submit;
        delete data.__v;
        if (data.token === "") {
            delete data.token;
        }
        if(typeof data.password === "undefined") {
            data.password = '__unchanged__';
        }

        submitRequest.set('Authorization', 'Bearer ' + this.props.auth.token)
            .send(data)
            .then((res) => {
                const savedUser = res.body;
                this.props.dispatch({type: 'NEW_SNACKBAR_MASSAGE', message: 'Abgespeichert!'});
                if (this.state.new) {
                    this.props.history.push('/users/edit/' + savedUser._id);
                    this.loadUser(savedUser._id);
                    this.setState({
                        new: false
                    });
                } else {
                    this.props.history.push('/users/list');
                }
            })
            .catch((error) => {
                if(error.status === 400) {
                    this.props.dispatch({type: 'NEW_SNACKBAR_MASSAGE', message: 'Das hat leider nicht geklappt!' + error.message});
                } else {
                    this.props.dispatch({type: 'NEW_SNACKBAR_MASSAGE', message: 'Das hat leider nicht geklappt!'});
                }
            });
    }

    deleteUser() {
        const newRequest = request.delete(this.props.app.host + '/users/' + this.props.match.params.userId);
        newRequest.set('Authorization', 'Bearer ' + this.props.auth.token)
            .send({})
            .then(() => {
                this.props.dispatch({type: 'NEW_SNACKBAR_MASSAGE', message: 'Benutzer wurde gelöscht!'});
                this.props.history.push('/users/list');
            })
            .catch((err) => {
                this.props.dispatch({type: 'NEW_SNACKBAR_MASSAGE', message: 'Das hat leider nicht geklappt!'});
                console.error("REQUEST FAILED", err);
            });
    }

    render() {
        const {classes} = this.props;
        if (typeof this.state.user.email === "undefined" || this.state.userGroups.length === 0) {
            return (<Loading/>);
        }

        const modalFormConfiguration = [
            {
                id: 'submit',
                type: 'submit',
                label: 'Benutzer endgültig löschen',
            },
            {
                id: 'cancel',
                type: 'cancel',
                label: 'Abbrechen',
            }
        ];

        const body = (
            <div style={styles.modal} className={classes.paper}>
                <h2 id="simple-modal-title">Benutzer löschen</h2>
                <p id="simple-modal-description">
                    Sind Sie sicher das sie diesen Benutzer löschen wollen?
                </p>
                <Form elements={modalFormConfiguration}
                      submit={this.deleteUser}
                      cancel={() => {
                          this.handleModalClose();
                      }}
                />
            </div>
        );

        let deleteDialog = (
            <>
                <Fab color="primary"
                     variant="extended"
                     aria-label="delete"
                     className={classes.fab}
                     onClick={this.handleModalOpen}
                >
                    <DeleteIcon/>
                    User löschen
                </Fab>
                <Modal
                    open={this.state.modalOpen}
                    onClose={this.handleModalClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {body}
                </Modal>
            </>
        )

        const formConfiguration = [
            {
                id: 'name',
                type: 'text',
                validate: (value) => {
                    return (value !== "");
                },
                label: 'Name',
                defaultValue: this.state.user.name
            },
            {
                id: 'email',
                type: 'text',
                validate: (value) => {
                    return (value !== "");
                },
                label: 'E-Mail',
                errorText: 'Bitte eine E-Mail Adresse eingeben',
                defaultValue: this.state.user.email,
            },
            {
                id: 'password',
                type: 'password',
                validate: (value) => {
                    return (value !== "");
                },
                label: 'Password',
                errorText: 'Bitte ein Passwort eingeben',
                defaultValue: this.state.user.email !== '' ? '__unchanged__' : '',
            },
            {
                id: 'token',
                type: 'text',
                disabled: true,
                label: 'API Key',
                defaultValue: this.state.user.token,
            },
            {
                id: 'createdAt',
                type: 'disabled',
                label: 'Erstellt am',
                defaultValue: this.state.user.createdAt,

            },
            {
                id: 'updatedAt',
                type: 'disabled',
                label: 'Zuletzt geändert am',
                defaultValue: this.state.user.updatedAt,
            },
            {
                id: 'submit',
                type: 'submit',
                label: 'Benutzer speichern',
            },
            {
                id: 'cancel',
                type: 'cancel',
                label: 'Abbrechen',
            }
        ];

        const groupFormConfiguration = [
            {
                id: 'submit',
                type: 'submit',
                label: 'Gruppen speichern',
            },
            {
                id: 'cancel',
                type: 'cancel',
                label: 'Abbrechen',
            }
        ];

        this.state.userGroups.reverse().forEach((groupName) => {
            groupFormConfiguration.unshift({
                id: groupName,
                type: 'checkbox',
                label: groupName.charAt(0).toUpperCase() + groupName.slice(1),
                default: this.state.user.scope.filter((scopeName) => {
                    return scopeName === groupName
                }).length > 0,
            });
        });

        let groupForm = (
            <Form elements={groupFormConfiguration}
                  submit={this.saveUserGroups}
                  cancel={() => {
                      this.props.history.goBack();
                  }}
            />
        )

        // No delete Dialog and no groups if new User
        if (this.props.match.params.userId === 'new') {
            deleteDialog = (<></>);
            groupForm = (<></>);
        }

        return (
            <Loading>
                <Paper className={classes.paperWrap}>
                    <Typography variant="h4" gutterBottom>
                        User {(this.props.match.params.userId === 'new') ? " erstellen" : " bearbeiten"}
                    </Typography>
                    {deleteDialog}
                    <Grid container spacing={24}>
                        <Grid item xs={3} component={Card} className={classes.card}>
                            <Typography variant="h6">
                                Felder
                            </Typography>
                            <Form elements={formConfiguration}
                                  submit={this.saveUser}
                                  cancel={() => {
                                      this.props.history.goBack();
                                  }}
                            />
                        </Grid>
                        <Grid item xs={3} component={Card} className={classes.card}>
                            <Typography variant="h6">
                                zugeordnete Gruppen
                            </Typography>
                            {groupForm}
                        </Grid>
                    </Grid>
                </Paper>
            </Loading>
        )
    }
}

export default connectStore(withRouter(withStyles(styles)(UserEditView)), {
    user: true,
});
