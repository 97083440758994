import request from 'superagent';
import Promise from 'bluebird';

global.Promise = Promise;

/**
 * Authentication handler class
 */
export default class Auth {
    constructor(props, mock = null) {
        this.props = props;

        if (mock !== null) {
            this.host = mock(request);
        }
    }

    login(email, pass, remember = false) {

        if (!email && !pass) {
            throw new Error('You need to supply email and password!');
        }

        return request.post(this.props.app.host + '/users/login')
            .send({ email: email, password: pass }) // sends a JSON post body
            .then(res => {
                this.props.dispatch({
                    type: 'AUTH_LOGGED_IN',
                    token: res.body.token,
                    apiToken: res.body.apiToken,
                    scopes: res.body.scopes,
                    remember
                });
                return true;
            })
            .catch((err) => {
                return false;
            });
    }

    logout(token) {
        this.props.dispatch({
            type: 'AUTH_LOGGED_OUT'
        });
    }
}
