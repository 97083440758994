import { Record } from 'immutable';

// The Article Reducer
const TableSettingRecord = Record({
    id: "",
    rows: [],
    rowsLength: 0,
    filteredRows: [],
    selected: [],
    fetch: {
        order: 'asc',
        orderBy: 'title',
        page: 0,
        rowsPerPage: 50,
        search: "",
    },
    tableColumns: [
        { id: 'title', numeric: false, disablePadding: true, label: 'Titel' },
    ],
});

export default TableSettingRecord;